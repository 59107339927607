import { Box, Typography } from "@mui/material";
import React from "react";

export default function NoDataFound({ text }) {
  return (
    <Box align="center" mt={4} width="100%" pb={3}>
      <img
        src="/assets/images/NoDataFound.png"
        style={{ maxWidth: "176px" }}
        alt=""
      />

      <Box mt={3} align="center">
        <Typography
          variant="h6"
          style={{
            marginTop: "-15px",
            textAlign: "center",
            color: "#000",
          }}
        >
          {text ? text : "No data, please try again later"}
        </Typography>
      </Box>
    </Box>
  );
}
