import { Fragment, Suspense, useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import AuthGuard from "../components/AuthGuard";
import UserLoginGuard from "../components/UserLoginGuard";
import { AuthContext } from "../context/auth";
export function RenderRoutes(props) {
  const auth = useContext(AuthContext);
  const { wallet, profile } = auth?.userData;
  //function to refresh the wallet deposit balances

  // const BlockHandlerStatus = () => {
  //   if (profile.user_status && profile?.user_status == -1) {
  //     auth.userLogIn(false, null);
  //     console.log("You have successfully logged out!");
  //     window.location.reload();
  //   }
  // };
  // useEffect(() => {
  //   BlockHandlerStatus();
  // }, [profile]);
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {props.data.map((route, i) => {
            const Component = route.component; //actuall page that has to be rendered on a specific route using lazy loading
            const Layout = route.layout || Fragment; //which layout has to we render with the page
            const Guard = route.guard
              ? AuthGuard
              : route.loginguard
              ? UserLoginGuard
              : Fragment; //authguard implementation to check weather ther user is loggedin or not
            return (
              <Route
                exact={route.exact}
                key={`route${i}`}
                path={route.path}
                element={
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        <RenderRoutes data={route.routes} />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
