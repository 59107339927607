import { Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { colors } from "../assets/colors";

export default function FlexButton({
  color,
  fullWidth,
  sx,
  title,
  onClick,
  type,
  disabled,
  icon,
  height,
}) {
  return (
    <Button
      height={height && height}
      fullWidth={fullWidth}
      sx={
        color === "primary"
          ? {
              backgroundColor: colors.green,
              color: colors.white,
              borderRadius: "5px",
              padding: "12px",
              border: `1px solid ${colors.green}`,
              textTransform: "none",
              "&:hover": {
                border: `1px solid ${colors.green}`,
                color: colors.green,
                background: colors.primaryWhite,
              },
              "&.Mui-disabled": {
                // backgroundColor: "lightgray",
                color: "#00000085",
              },
              ...sx,
            }
          : color === "secondary"
          ? {
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "5px",
              padding: "12px",
              textTransform: "none",
              border: `1px solid ${colors.btnBlue}`,
              "&:hover": {
                border: `1px solid ${colors.btnBlue}`,
                background: "#fff",
                color: colors.btnBlue,
              },
              ...sx,
            }
          : sx
      }
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon}&nbsp;{title}
    </Button>
  );
}

FlexButton.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
};
