import React, { useContext, useEffect, Fragment } from "react";
import { AuthContext } from "../context/auth";
import ErrorModal from "./modals/ErrorModal";
import { useLocation } from "react-router";

export default function MaintenanceMode() {
  const location = useLocation();
  const auth = useContext(AuthContext);
  const { isMaintenanceActive } = auth;
  useEffect(() => {
    auth.getKYCStatus(window.sessionStorage.getItem("user_id"));
    if (location.pathname) {
      if (isMaintenanceActive) {
        auth.setIsMaintenanceActive(true);
      } else {
        auth.setIsMaintenanceActive(false);
      }
    }
    return () => {};
  }, [location.pathname, isMaintenanceActive]);

  return (
    <Fragment>
      {isMaintenanceActive && (
        <ErrorModal
          open={isMaintenanceActive}
          title="Maintenance Mode!"
          description={`We are working on some points, we will notify you once we are done!`}
          // close={() => auth.setIsMaintenanceActive(false)}
        />
      )}
    </Fragment>
  );
}
