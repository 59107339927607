import React from "react";
import { Box, Grid } from "@mui/material";
import SupportButton from "./SupportButton";

const style = {
  overflow: "hidden",
  position: "relative",
  background:
    "linear-gradient(101deg,#FFDFB8 -2.08%,#FFD0E3 52.7%,#C0C5FF 104.17%)",
  backdropFilter: "blur(100px)",
  borderRadius: "20px",
  ":before": {
    content: '" "',
    position: "absolute",
    background: "rgba(255,255,255,.2)",
    height: "639.788px",
    width: "639.788px",
    borderRadius: "100%",
    top: "0px",
    left: "-431px",
  },
  ":after": {
    content: '" "',
    position: "absolute",
    background: "rgba(255,255,255,.2)",
    height: "600.788px",
    width: "600.788px",
    borderRadius: "100%",
    top: "-139px",
    right: "-382px",
  },
};

const FormCard = ({ children, sx }) => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ ...sx, ...style }}
    >
      <Box
        sx={{
          zIndex: "99",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default FormCard;
