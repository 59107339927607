export const gradientColor = {
  backgroundImage: "linear-gradient(151deg, #589bfc 18.12%, #89b5f5 71.56%)",
  WebkitBackgroundClip: "text",
  color: "transparent",
};

export const socket_access_token = "kujgwvfq-a-ghosttown-z-1fhhup0p6";
export const img_base_url = "https://taralitykyc.blr1.digitaloceanspaces.com";
export const google_auth_client_id =
  "993552139175-dcvi366ebi5os48gtad3qmbb0pqdvv82.apps.googleusercontent.com";
export const chaiImgFinder = (chian) => {
  switch (chian) {
    case "erc20":
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png";
    case "bep20":
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png";
    case "trc20":
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png";

    default:
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png";
  }
};
